/*  eslint-disable jsx-a11y/control-has-associated-label */
import React, { useContext } from 'react';
import '../styles/results-toggle-layout.scss';
import { func, string, bool } from 'prop-types';
import { Grid, List, Table } from './ToggleImages/ToggleImages';
import { publish } from '../publisher';
import { ProductResultsContext } from './ProductResultsContext';

const ResultsToggleLayout = ({
  activeLayout, toggleActiveLayout, showToggle, isTableView, hideListIcon,
}) => {
  const { data } = useContext(ProductResultsContext);
  const { searchReport } = data?.searchModel || {};
  const { totalProducts = 0 } = searchReport || {};
  const GRID = 'Grid';
  const LIST = 'List';
  const TABLE = 'Table';
  const ACTIVE_COLOR = '#F96302';
  const INACTIVE_COLOR = '#777777';
  let gridColor;
  let listColor;
  let tableColor;

  const toggleLayout = (newLayout) => {
    if (newLayout === activeLayout) {
      return;
    }
    toggleActiveLayout(newLayout);
    publish('select-list-layout', newLayout);
  };

  if (activeLayout === GRID) {
    gridColor = ACTIVE_COLOR;
    listColor = INACTIVE_COLOR;
    tableColor = INACTIVE_COLOR;
  } else if (activeLayout === LIST) {
    gridColor = INACTIVE_COLOR;
    listColor = ACTIVE_COLOR;
    tableColor = INACTIVE_COLOR;
  } else if (activeLayout === TABLE) {
    gridColor = INACTIVE_COLOR;
    listColor = INACTIVE_COLOR;
    tableColor = ACTIVE_COLOR;
  }

  return (
    <>
      {(showToggle || totalProducts > 0) && (
        <div className="results-layout__wrapper" data-component="ResultsToggleLayout">
          {isTableView && (
            <button
              className="results-layout__toggle results-layout__toggle-table"
              type="button"
              onClick={() => toggleLayout(TABLE)}
            >
              <Table fill={tableColor} />
            </button>
          )}
          <button
            className="results-layout__toggle results-layout__toggle-grid"
            type="button"
            onClick={() => toggleLayout(GRID)}
          >
            <Grid fill={gridColor} />
          </button>
          {!hideListIcon && (
            <button
              className="results-layout__toggle results-layout__toggle-list"
              type="button"
              onClick={() => toggleLayout(LIST)}
            >
              <List fill={listColor} />
            </button>
          )}
        </div>
      )}
    </>
  );
};

ResultsToggleLayout.propTypes = {
  toggleActiveLayout: func.isRequired,
  activeLayout: string.isRequired,
  showToggle: bool,
  isTableView: bool,
  hideListIcon: bool,
};

ResultsToggleLayout.defaultProps = {
  showToggle: false,
  isTableView: false,
  hideListIcon: false,
};

ResultsToggleLayout.displayName = 'ProductResultsToggleLayout';

export { ResultsToggleLayout };
