import React, { useEffect } from 'react';
import { string, shape, bool } from 'prop-types';
import classNames from 'classnames';
import { Col, Row, Carousel } from '@thd-olt-component-react/core-ui';
import { useDataModel } from '@thd-nucleus/data-sources';
import { RentalIntentNoResults } from './RentalIntentNoResults/RentalIntentNoResults';
import styles from '../styles/rental-intent.module.scss';
import { dataModel } from '../dataModel';
import { RentalIntentPodDesktop } from './RentalIntentPod/RentalIntentPodDesktop';

const RentalIntentDesktop = (props) => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('rental-intent.ready'); }, []);

  const {
    keyword
  } = props;

  // eslint-disable-next-line react/destructuring-assignment
  const isControlledData = typeof props.data !== 'undefined' || typeof props.loading !== 'undefined';

  const skipSearch = isControlledData || !keyword;

  const searchResponse = useDataModel('searchModel', {
    variables: {
      keyword
    },
    skip: skipSearch
  });

  let data;

  // eslint-disable-next-line react/destructuring-assignment
  if (typeof props.data !== 'undefined') {
    ({ data } = props);
  } else {
    ({ data } = searchResponse);
  }

  const { intents, universalSearchArray, productTypes } = data?.searchModel?.orangeGraph || {};
  const { correctedKeyword } = data?.searchModel?.searchReport || '';

  const title = correctedKeyword || keyword;

  if (!intents?.includes('rental')) return null;

  if (!universalSearchArray || universalSearchArray.length === 0) return null;

  const { pods } = universalSearchArray[0];
  const rentalPods = pods
    .filter((pod) => pod?.recordType?.toUpperCase()?.includes('RENTAL'));

  if (rentalPods.length === 0) return <RentalIntentNoResults productTypes={productTypes} />;

  return (
    <Row className={classNames(styles['rental-intent'])}>
      <Col fallback="12" nopadding>
        <div className={classNames(styles['rental-intent__header'])}>
          <div className={classNames(styles['rental-intent__header-title'])}>
            <span className={classNames(styles['rental-intent__header-text'])}>{title}</span>
          </div>
        </div>
        <div className={classNames(styles['rental-intent-pod__wrapper'])}>
          <Carousel
            breakpoints={{
              sm: {
                slidesPerView: 2,
                slidesPerGroup: 2
              },
              md: {
                slidesPerView: 3,
                slidesPerGroup: 3
              },
              lg: {
                slidesPerView: 4,
                slidesPerGroup: 4
              },
              xl: {
                slidesPerView: 5,
                slidesPerGroup: 5
              }
            }}
            className={classNames(styles['rental-intent-pod__wrapper'])}
          >
            {rentalPods.map((pod, index) => (
              <RentalIntentPodDesktop
                key={`${'rental-intent-pod-' + index}`}
                index={index}
                title={pod.title}
                description={pod.description}
                link={pod.link}
                imageUrl={pod.imageUrl}
              />
            ))}
          </Carousel>
        </div>
      </Col>
    </Row>
  );
};

RentalIntentDesktop.displayName = 'RentalIntent';

RentalIntentDesktop.propTypes = {
  keyword: string,
  data: shape({}),
  loading: bool,
};

RentalIntentDesktop.defaultProps = {
  keyword: '',
  data: undefined,
  loading: undefined
};

RentalIntentDesktop.dataModel = dataModel;

export { RentalIntentDesktop };
